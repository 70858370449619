$widthSp: 375;
$widthPc: 1440;
$widthTab: 1024;


// .l-loading {
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   z-index: 999;
//   background: #000000;;
//   text-align:center;
//   opacity: 1;
//   left: 0;
//   top: 0;
//   visibility: visible;
//   &_img {
//     position: absolute;
//     top: 50%;
//     transform: translate(-50%,-50%);
//     left: 50%;
//     img {
//       width: MinWidthSize(480);
//       @media #{$breakpoint}{
//         width: getVw(240,$widthSp);
//       }
    
//     }
//   }
// }

.l-top_kv {
  position: relative;
  &_wrapper {
    height: getVw(768,$widthPc);
    position: relative;
    z-index: 1;
    // @media #{$breakpointpc}{
    //   height: getCl(378,768,768);
    // }
    @media #{$breakpoint}{
      width: 100%;
      height: getVw(580,$widthSp);
    }
  }
  &_video {
    position: absolute;
    top: -1rem;
    left: 0;
    width: 100%;
    @media #{$breakpoint}{
      top: 0; 
    }
    video {
      width: 100%;
      height: 100%;
      vertical-align: top;
      @media #{$breakpoint}{
        height: getVw(550,$widthSp); 
        object-fit: cover;
      }
    }
  }
  &_copy {
    position: relative;
    top: getVw(328,$widthPc);
    text-align: right;
    letter-spacing: .08em;
    @media #{$breakpoint}{
      top: getVw(287,$widthSp);
      padding-right: 1rem;
    }
    &_txt {
      color: #ffffff;
      display: block;
      font-weight: 700;
      font-size: getCl(15,375,30);
      // @media #{$breakpoint}{
      //   font-size: getVw(30,$widthPc);
      // }
    }
    &_img {
      width: MinWidthSize(610);
      margin-top: MinWidthSize(10);
      @media #{$breakpoint}{
        width: calc(100% - 1.5rem); 
        margin-top: 0;
      }

    }
  }
  &_btn {
    &_link {
      display: block;
      transform: rotate(45deg);
      position: absolute;
      bottom: getVw(-50,$widthPc);
      right: getVw(20,$widthPc);
      // overflow: hidden;
      width: 22rem;
      margin: 2.5rem auto;
      @media #{$breakpoint}{
        width: 17rem;
        bottom: getVw(-40,$widthSp);
        right: 0;
         overflow: hidden;
      }
      a {
        display: block;
        // clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
      }
    }
    &_img {
      display: block;
      transform: rotate(-45deg);      
      transition: transform 0.4s;
      width: 30rem;
      margin: -2.5rem auto;
      clip-path: polygon(50% 0%, 120% 50%, 50% 120%, 0% 50%);

      // width: getVw(200,$widthPc);
      @media #{$breakpoint}{
        width: getVw(170,$widthSp);
      }
      &:hover {
        transform: scale(1.1) rotate(-45deg);
        @media #{$breakpoint}{
          transform: scale(1) rotate(-45deg);
        }
      }
    }
  }
}

.l-top_media {
  background: #f6f6f6;
  margin-bottom: MaxWidthSize(-90);
  @media #{$breakpointpc}{
    margin-bottom: 0;
  }
  &_wrapper {
    position: relative;
    top: MaxWidthSize(-210);
    @media #{$breakpointpc}{
      top: MaxWidthSize(-110);
      @media #{$breakpoint}{
        padding: 0 0 3rem; 
        top: -3rem;
      }
    }
    // top: getVw(-110,1440);
  }
  &_news {
    padding: MinWidthSize(58);
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f6f6f6;
    background: #ffffff;
    @media #{$breakpointtab}{
      padding: 3.6rem 2rem 6rem;
      @media #{$breakpoint}{
        display: block; 
        padding: 3.6rem 2rem 3rem;
      }
    }
    &_head {
      width: 25rem;
      @media #{$breakpoint}{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 3rem;
      }
      &_ttl {
        display: block;
        text-align: left;
        line-height: 1.1;
        &_jp {
          display: block;
          @media #{$breakpoint}{
            display: none; 
          }
        }
      }
      &_link {
        min-width: 0;
        width: 19rem;
        @media #{$breakpoint}{
          width: 3.5rem; 
        }
      }
    }
    &_list {
      width: calc(33% - 1.8rem);
      line-height: (48/32);
      @media #{$breakpointtab}{
        max-width: calc(100% - 2vw); 
        @media #{$breakpoint}{
          max-width: 100%; 
        }
      }
      &_wrapper {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 25rem);
        @media #{$breakpoint}{
          width: 100%; 
        }
      }
      &_link {
        display: flex;
        flex-direction: column-reverse;
        @media #{$breakpoint}{
          width: calc(100% - 5rem); 
          margin: auto;
        }
      }
      &_tag {
        color: #ffffff;
        border-radius: 4px;
        // background-image: -moz-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
        // background-image: -webkit-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
        // background-image: -ms-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
        // height: MinWidthSize(25);
        @include tagBlue;
        min-width: 8rem;
        max-width: 15rem;
        padding: MinWidthSize(4) getCl(8,$widthTab,15);
        font-size: 1.2rem;
        margin-right: MinWidthSize(20);
        letter-spacing: .08em;
        @media #{$breakpointtab}{
          margin-right: 1rem;
          min-width: 8rem;
          max-width: 15rem;
          padding: .4rem .5rem; 
          min-width: 8rem;
          @media #{$breakpoint}{
            margin-right: 2rem; 
          }
        }
        &_wrapper {
          display: flex;
          align-items: center;
          margin-bottom: MinWidthSize(15);
          @media #{$breakpointtab}{
            margin-bottom: 1.4rem; 
          }
        }
      }
      &_img {
        // width: 100%;
        // width:MinWidthSize(285);
        // height: MinWidthSize(190);
        object-fit: cover;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &.is-height {
          width: auto;
          height: 100%;
      }
      &.is-width {
          width: 100%;
          height: auto;
      }
        // @media #{$breakpointtab}{
        //   width: 100%;
        //   height: auto; 
        // }
        &_wrapper {
          width:MinWidthSize(285);
          height: MinWidthSize(190);
          position: relative;
          background: #d2d2d2;
          @media #{$breakpointtab}{
            width: 100%;
            // height: getVw(190,$widthTab);
            height: 15.8vw;
            line-height: 19rem;
            // padding: 0 2.5rem; 
            margin-bottom: 2rem;
            @media #{$breakpoint}{
              height: getVw(190,$widthSp); 
            }
          }
          // &.is-height {
          //   img {
          //     width: auto;
          //     height: 100%;
          //   }
          // }
          // &.is-width {
          //   img {
          //     width: 100%;
          //     height: auto;
          //   }
          // }
        }
      } 
      &_content {
        margin-top: MinWidthSize(15);
        @media #{$breakpointtab}{
          // padding: 0 2.5rem; 
          margin-top: 0;
        }
      }
      &_txt {
        font-size: getCl(14,$widthSp,16);
        text-align: left;
        letter-spacing: .08em;
        line-height: (48/32);
        @media #{$breakpoint}{
          font-size: 1.4rem;
          line-height: (48/28);
          margin-bottom: 4rem; 
        }
      }
    }
    &_slider {
      &_prev {
        position: absolute;
        height: calc(100% - 18.2rem);
        left:0;
        z-index: 1;
        @media #{$breakpointtab}{
          height: 0;
          top: 8vw; 
          @media #{$breakpoint}{
            top: 24.5vw; 
          }
        }
        &::after {
          @include elementImg;
          height: 1.2rem;
          width: 1.7rem;
          transform: rotate(180deg);
          background: url(#{$imgPath}top/l-top_media_arrow.svg);
          @media #{$breakpointtab}{
            left: -2rem; 
            @media #{$breakpoint}{
              left: auto; 
            }
          }
        }
      }
      &_next {
        position: absolute;
        height: calc(100% - 18.2rem);
        right:1.8rem;
        @media #{$breakpointtab}{
          height: 0;
          top: 8vw; 
          @media #{$breakpoint}{
            top: 24.5vw; 
          }
        }
        &::after {
          @include elementImg;
          height: 1.2rem;
          width: 1.7rem;
          background: url(#{$imgPath}top/l-top_media_arrow.svg);
          @media #{$breakpointtab}{
            right: -3.7rem; 
            @media #{$breakpoint}{
              right: auto; 
            }
          }
        }
      }
    }
  }
  &_ir {
    padding: MinWidthSize(58);
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f6f6f6;
    background: #ffffff;
    @media #{$breakpointtab}{
      padding-left: 2rem;
      padding-right: 2rem; 
      @media #{$breakpoint}{
        display: block; 
        padding: 3rem 2rem;
      }
    }
    &_head {
      width: 25rem;
      @media #{$breakpoint}{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between; 
        margin-bottom: 4rem;
      }
      &_ttl {
        display: block;
        text-align: left;
        line-height: 1.1;
        &_jp {
          display: block;
          @media #{$breakpoint}{
            display: none; 
          }
        }
      }
      &_link {
        min-width: 0;
        width: 19rem;
        @media #{$breakpoint}{
          width: 3.5rem;
          margin-top: 0; 
        }
      }
    }
    &_list {
      // width: calc(33% - 1.8rem);
      line-height: (48/32);
      text-align: left;
      border-bottom: 1px solid #f6f6f6;
      padding: MinWidthSize(15) 0 MinWidthSize(30);
      margin-bottom: MinWidthSize(10);
      @media #{$breakpoint}{
        margin-bottom: 0rem; 
        padding: 2rem 0;
      }
      &:first-child {
        padding-top: 0;
        @media #{$breakpoint}{
          padding-top: 0; 
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      &_wrapper {
        width: calc(100% - 25rem);
        @media #{$breakpoint}{
          width: 100%; 
          padding-bottom: 2rem;
        } 
      }
      &_link {
        // text-decoration: underline;
        // text-decoration-color:#1564c5;
        border-bottom: 1px solid #1564c5;
        margin-right: 1rem;
        @media #{$breakpoint}{
          margin-right: 0; 
        }
      }
      &_tag {
        &_time {
          display: block;
          margin-bottom: MinWidthSize(3);
          @media #{$breakpoint}{
            margin-bottom: 0;
            line-height: 1.6; 
          }
        }
      }
      &_content {
        margin-top: MinWidthSize(20);
      }
      &_txt {
        font-size: getCl(14,$widthSp,16);
        text-align: left;
        line-height: (48/32);
        letter-spacing: .08em;
      }
    }
  }
}

.l-top_tec {
  background: #ffffff;
  padding: MinWidthSize(160) 0 MinWidthSize(90);
  @media #{$breakpoint}{
    padding: 10.6rem 0 6.6rem; 
  }
  &_head {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: MinWidthSize(55);
    @media #{$breakpoint}{
      margin-bottom: 4.6rem; 
    }
    &_ttl {
      width: 100%;
      &_jp {
        display: block;
        font-size: getCl(16,$widthSp,24);
        line-height: 2;
        margin-bottom: MinWidthSize(20);
        @media #{$breakpoint}{
          line-height: (72/32); 
          margin-bottom: 0;
        }
      }
      &_en {
        display: block;
        line-height: 1.2;
        @media #{$breakpoint}{
          line-height: 1.1; 
        }
        &_wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
  &_content {
    padding-bottom: 0rem;
  }
}

.l-top_products {
  background: #ffffff;
  padding:0 0 MinWidthSize(160);
  @media #{$breakpoint}{
    padding: 0rem 0 11.6rem; 
  }
  &_head {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_ttl {
      width: 100%;
      // margin-bottom: MinWidthSize(43);
      // @media #{$breakpoint}{
      //   margin-bottom: 4.3rem; 
      // }
      &_en {
        &_wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          line-height: 1.2;
        }
      }
    }
  }
}

.l-top_link {
  padding: MinWidthSize(80) 0 MinWidthSize(160);
  @media #{$breakpoint}{
    padding: 8rem 0 12rem; 
  }
}

.l-top_about {
  padding-bottom:MinWidthSize(160);
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media #{$breakpoint}{
    padding-bottom:12.6rem;
    flex-direction: column-reverse; 
  }
  &_content {
    width: 52rem;
    margin-right: MinWidthSize(160);
    @media #{$breakpoint}{
      width: 100%; 
      margin-right: 0;
    }
  }
  &_ttl {
    width: 100%;
    &_jp {
      display: block;
      line-height: 2.2;
      @media #{$breakpoint}{
        line-height: (72/32); 
        margin-bottom: 0;
      }
    }
    &_en {
      font-size: getCl(40,$widthSp,100);
      display: block;
      // line-height: 1.2;
      line-height: 1;
      margin-bottom: MinWidthSize(36);
      @media #{$breakpoint}{
        line-height: 1.1; 
        margin-bottom: 2.4rem;
      }
    }
    &_sub {
      line-height: (88/64);
      color: #222222;
      font-weight: 700;
      font-size: getCl(24,$widthSp,32);
      margin-bottom: MinWidthSize(30);
      @media #{$breakpoint}{
        line-height: (72/48); 
        margin-bottom: 1.6rem;
      }
    }
  }
  &_txt {
    margin-bottom: MinWidthSize(60);
    @media #{$breakpoint}{
      margin-bottom: 2rem; 
    }
  }
  &_photo {
    &_wrapper {
      width: MinWidthSize(600);
      @media #{$breakpoint}{
        width: 100%;
        margin-bottom: 1.6rem;
      }
    }
  }
}

.l-top_member {
  padding-bottom:MinWidthSize(90);
  text-align: left;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  @media #{$breakpoint}{
    padding-bottom:8.6rem;
    flex-direction: column-reverse; 
  }
  &_content {
    width: 43rem;
    margin-left: MinWidthSize(70);
    @media #{$breakpoint}{
      width: 100%; 
      margin-left: 0;
    }
  }
  &_ttl {
    width: 100%;
    &_jp {
      display: block;
      line-height: 1;
      @media #{$breakpoint}{
        line-height: (72/32); 
        margin-bottom: 0;
      }
    }
    &_en {
      font-size:getCl(40,$widthSp,80);
      // font-size: getCl(40,$widthSp,100);
      display: block;
      line-height: 1.2;
      margin-bottom: MinWidthSize(5);
      @media #{$breakpoint}{
        line-height: 1.1; 
        margin-bottom: 1rem;
      }
    }
    &_sub {
      line-height: (88/64);
      color: #222222;
      font-size: getCl(24,$widthSp,32);
      margin-bottom: MinWidthSize(30);
      @media #{$breakpoint}{
        line-height: (72/48); 
        margin-bottom: 1.6rem;
      }
    }
  }
  &_txt {
    margin-bottom: MinWidthSize(55);
    font-size: getCl(16,$widthSp,20);
    line-height: (70/40);
    font-weight: 700;
    @media #{$breakpoint}{
      margin-bottom: 3rem; 
      line-height: (72/32);
    }
  }
  &_photo {
    &_wrapper {
      width: MinWidthSize(760);
      @media #{$breakpoint}{
        width: 100%;
        margin-bottom: 0rem;
      }
    }
  }
}

.l-top_career {
  overflow: hidden;
  padding-top: MinWidthSize(160);
  padding-bottom:MinWidthSize(290);
  text-align: left;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  @media #{$breakpoint}{
    padding-top: 0;
    padding-bottom:18.5rem;
    flex-direction: column-reverse; 
  }
  &_wrapper {
    position: relative;
    @media #{$breakpoint}{
      padding-left: 0;
      padding-right: 0; 
    }
  }
  &_content {
    width: MinWidthSize(760);
    padding: MinWidthSize(120) MinWidthSize(84) MinWidthSize(130) MinWidthSize(74);
    background: #ffffff;
    position: relative;
    z-index: 1;
    @media #{$breakpoint}{
      width: 100%; 
      padding: 6.4rem 2rem 7.6rem;
    }
  }
  &_ttl {
    width: 100%;
    &_jp {
      display: block;
      line-height: 1;
      @media #{$breakpoint}{
        line-height: (72/32); 
        margin-bottom: 0;
      }
    }
    &_en {
      // font-size: getCl(40,$widthSp,100);
      font-size:getCl(40,$widthSp,80);
      display: block;
      line-height: 1.2;
      margin-bottom: MinWidthSize(20);
      @media #{$breakpoint}{
        line-height: 1.1; 
        margin-bottom: 2.3rem;
      }
    }
  }
  &_txt {
    margin-bottom: MinWidthSize(55);
    font-size: getCl(18,$widthSp,28);
    line-height: (88/56);
    font-weight: 700;
    letter-spacing: .12em;
    color: #222222;
    @media #{$breakpoint}{
      margin-bottom: 4rem; 
      line-height: (58/36);
    }
  }
  &_logo {
    width: MinWidthSize(780);
    @media #{$breakpoint}{
      width: 26.4rem;
      margin-bottom: 0rem;
    }
    &_wrapper {
      position: absolute;
      top: MaxWidthSize(-100);
      right: MaxWidthSize(-40);
      @media #{$breakpoint}{
        top: auto;
        bottom: -15.6rem;
        left: 2rem;
        z-index: 2;
        width: 27rem;
      }
    }
  }
}


.slick-dots {
	li {
		width: 12px;
		height: 12px;
    margin: 0 12px 0 0;
    &:last-child {
      margin-right: 0;
    }
		button {
			width: 12px;
			height: 12px;
			&::before {
				content: "";
				background: #888888;
				border-radius: 50%;
				opacity: 1;
				width: 12px;
				height: 12px;
			}
		}
	}
}


.slick-dots li.slick-active button:before {
  background-image: -moz-linear-gradient( 90deg, rgb(22,50,165) 0%, rgb(42,180,250) 100%);
  background-image: -webkit-linear-gradient( 90deg, rgb(22,50,165) 0%, rgb(42,180,250) 100%);
  background-image: -ms-linear-gradient( 90deg, rgb(22,50,165) 0%, rgb(42,180,250) 100%);
	border-radius: 50%;
	width: 12px;
	height: 12px;
	opacity: 1;
}

.slick-dots {
	width: auto;
	left: -16px;
	bottom: -44px;
	@media #{$breakpointtab}{
		bottom: 0;
    left: -11px;
    left: 50%;
    transform: translateX(-50%);
	}
}